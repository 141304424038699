import React from "react";
import "rsuite/dist/rsuite.css";
import isAfter from "date-fns/isAfter";

import "./styles.css";
import moment from "moment";

import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
// type ValueType = [Date, Date];

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 15 days",
    value: [subDays(new Date(), 14), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
];
export default function DatePicker(props) {
  const { handleChange } = props;

  return (
    <div className="dates">
      <DateRangePicker
        ranges={predefinedRanges}
        showOneCalendar
        placeholder="Select Date Range"
        format="dd/MM/yyyy"
        disabledDate={(date) => isAfter(date, new Date())}
        onOk={(value) => {
          console.log("onOk ", value);

          const dateRangeArr = [];
          value.forEach((element) => {
            dateRangeArr.push(moment(element).format("YYYY-MM-DD"));
          });

          handleChange(dateRangeArr);
        }}
        onChange={(value) => {
          console.log("onChange ", value);
          const dateRangeArr = [];
          if (value) {
            value.forEach((element) => {
              dateRangeArr.push(moment(element).format("YYYY-MM-DD"));
            });
          }
          handleChange(dateRangeArr);
        }}
        character="   To   "
      />
    </div>
  );
}
