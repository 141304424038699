import React from "react";
import Table from "@mui/material/Table";
import moment from "moment";
import noData from "../../assets/noData.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"; // import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import services from "../../services/services";
import styles from "./mastertable.module.scss";
import Sidebar from "../sidebar/Sidebar";
import { styled } from "@mui/material/styles";

export default function MasterConfigTable() {
  const [tableData, setTableData] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedTableName, setSelectedTableName] = useState(null);

  const [tableNames, setTableNames] = useState(null);
  const [tableKeys, setTableKeys] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);

  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  }));
  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setPage(newPage);
    fetchTableData(selectedTable, newPage,rowsPerPage);
  };

  useEffect(() => {
    fetchTablesName();
  }, []);
  const fetchTablesName = async () => {
    await services
      .getTableName()
      .then((response) => {
        if (response) {
          setLoader(false);
          if (response) {
            setTableKeys(Object.keys(response));
            setTableNames(Object.values(response));
          }
        }
      })
      .catch((e) => {
        // console.log("catch if", e);
      });
    // if (response) {
    //   setTableKeys(Object.keys(response));
    //   setTableNames(Object.values(response));
    // }
  };
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const fetchTableData = async (index, page,limit) => {
    setLoader(true);
    setTableData(null);
    await services
      .getTableData(tableKeys[index], page,limit)
      .then((response) => {
        setLoader(false);
        if (response) {
          // console.log("fetchTableData", response.data);
          // console.log("Object ", Object.keys(response.data[0]));
          // console.log("fetchTableData", response.data);
          // console.log("Object ", Object.keys(response.data[0]));

          setTableHeaders(Object.keys(response.data[0]));
          setTableData(response.data);
          setTotalCount(response.total_count);
        }
      })
      .catch((e) => {
        setLoader(false);

        // console.log("catch if", e);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchTableData(selectedTable, 0 ,event.target.value);

  };

  return (
    <Grid container padding={2} spacing={2}>
      <Grid item xs={3} md={3}>
        {tableNames &&
          tableNames.map((item, index) => (
            <Box
              key={index}
              className={
                selectedTable === index
                  ? styles.selectedTableName
                  : styles.tableNames
              }
              onClick={() => {
                // console.log("selected table", item);
                setSelectedTable(index);
                fetchTableData(index, page,rowsPerPage);
                setSelectedTableName(item);
              }}
              sx={{ cursor: "pointer" }}
            >
              <Typography>{item}</Typography>
            </Box>
          ))}
      </Grid>

      {selectedTableName ? (
        <Grid
          item
          xs={9}
          md={9}
          // className={styles.nodataView}
          overflow={"hidden"}
          // mx={7}
        >
          <TableContainer
            component={Paper}
            style={{
              alignSelf: "center",
              maxHeight: "450px",
              boxShadow: "none",
              borderRadius: "0px 0px 0px 0px",
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              {!loader && (
                <TableHead className={styles.headerclass}>
                  <TableRow>
                    {tableHeaders &&
                      tableHeaders.map((item, index) => (
                        <TableCell>
                          <Typography key={index} className={styles.headerText}>
                            {item}
                          </Typography>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {!loader ? (
                  tableData &&
                  tableData.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {tableHeaders.map((col, i) => (
                        <TableCell
                          key={i}
                          component="th"
                          scope="row"
                          // className={styles.rowText}
                        >
                          <Typography className={styles.rowText}>
                            {row[col] ? row[col] : ""}
                          </Typography>
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ height: "396px", width: "100%" }}
                        sx={{ bgcolor: "grey.150" }}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!loader && (
            <TablePagination
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={totalCount}
              page={page}
              labelDisplayedRows={({
                from,
                to,
                count,
              }) => `Showing Results of ${from}–${to} of ${
                totalCount !== -1 ? totalCount : `more than ${to}`
              }
                    `}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              component={Paper}
              style={{
                boxShadow: "none",
                borderRadius: "0px 0px 0px 0px",
              }}
            />
          )}
        </Grid>
      ) : (
        <Grid
          item
          xs={9}
          md={9}
          className={styles.nodataView}
          overflow={"hidden"}
          // mx={7}
        >
          <Box className={styles.nodataView}>
            <img src={noData} alt="noData" />
            <Typography className={styles.titleText}>
              You have not selected any table yet.
            </Typography>
            <Typography className={styles.text}>
              Please select the table to see the details
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
