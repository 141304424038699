import * as React from "react";
import { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import {
  Typography,
  Grid,
  List,
  ListItem,
  Box,
  Stack,
  //AccordionDetails,
  //  Accordion,
} from "@mui/material";
import { FAQs } from "./faqs";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  height: "1%",
}));
export default function BasicAccordion() {
  const [selected, setSelected] = useState(false);
  const handleChange = (id) => (event, isExpanded) => {
    const val = isExpanded ? id : false;
    setSelected(val);
  };
  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
            fontSize: "2rem",
          },
          ".expandIconWrapper": {
            display: "none",
            fontSize: "1rem",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
            fontSize: "1rem",
          },
        }}
      >
        <div className="expandIconWrapper">
          <RemoveOutlinedIcon
            fontSize="medium"
            color="secondary"
          ></RemoveOutlinedIcon>
        </div>
        <div className="collapsIconWrapper">
          <AddOutlinedIcon
            fontSize="medium"
            color="secondary"
          ></AddOutlinedIcon>
        </div>
      </Box>
    );
  };
  return (
    <Grid container mt={5}>
      <Grid item md={12}>
        <Box marginX={2}>
          <Typography>FAQ's</Typography>
        </Box>
      </Grid>
      <Grid
        item
        md={12}
        mt={5}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box marginX={2}>
          {FAQs.length > 0 &&
            FAQs.map((q, index) => (
              <Item>
                <Accordion
                  expanded={selected === q.id}
                  onChange={handleChange(q.id)}
                  sx={{ backgroundColor: "white" }}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<CustomExpandIcon />}
                    sx={{ backgroundColor: "white" }}
                  >
                    <Typography color="secondary">{q.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#fff" }}>
                    <Stack direction="column" width="100%">
                      {q.ans.title && (
                        <Typography mb={"1rem"} sx={{ fontWeight: 600 }}>
                          {q.ans.title}
                        </Typography>
                      )}
                      {q.ans.lines && q.ans.lines.length > 0 && (
                        <List
                          sx={{
                            listStyleType: "disc",
                            "& .MuiListItem-root": {
                              display: "list-item",
                            },
                            ml: "2rem",
                          }}
                        >
                          {q.ans.lines.map((line, index) => (
                            <Typography
                              sx={{
                                pl: "1rem",
                              }}
                            >
                              <ListItem>
                                <Typography>{line}</Typography>
                              </ListItem>
                            </Typography>
                          ))}
                        </List>
                      )}
                      {q.ans.steps && q.ans.steps.length > 0 && (
                        <List
                          sx={{
                            //listStyleType: "disc",
                            // pl: 2,
                            "& .MuiListItem-root": {
                              display: "list-item",
                            },
                            ml: "1rem",
                            color: "secondary",
                            //mt: "2rem",
                          }}
                        >
                          {q.ans.steps.map((line, i) => (
                            <>
                              <Typography
                                //color={"secondary"}
                                sx={{
                                  pl: "1rem",
                                  mb: "1rem",
                                }}
                              >
                                <b>{`Step ${i + 1}:`} </b> {`${line}`}
                              </Typography>
                              {q.ans.images &&
                                q.ans.images.length > 0 &&
                                q.ans.images.map((image, j) => {
                                  if (i === j && image)
                                    return (
                                      <Grid
                                        item
                                        md={12}
                                        width={"100%"}
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <img
                                          width="60%"
                                          height="60%"
                                          src={image}
                                          alt="Telus Health"
                                          // sx={{ flexGrow: 1 }}
                                        />
                                      </Grid>
                                    );
                                })}
                            </>
                          ))}
                        </List>
                      )}
                      {q.ans.finalMessage && (
                        <Typography
                        // color={"secondary"}
                        //mt={"1rem"}
                        //fontSize={"1.2rem"}
                        >
                          {q.ans.finalMessage}
                        </Typography>
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Item>
            ))}
        </Box>
        <Box m={2}>
          For further assistance, please reach out to us via email at <a href="mailto:analyticsadmin@teslushealth.com">analyticsadmin@teslushealth.com</a>.
        </Box>
      </Grid>
    </Grid>
  );
}
