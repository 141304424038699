export default function responseParse(response) {
  return new Promise((resolve, reject)=> {
    switch (response?.status) {
      case 200:
        // const result = await response.json();
        resolve(response.data);
        break;
      case 400:
        reject(sessionExpired());
        break;
      case 422:
        reject(new HTTPError(response.status, "Error performing action. Please validate input fields"));
        break;
      case 500:
        reject(new HTTPError(response.status, "Internal Server Error. Please contact site admin"));
        break;
      default:
        // const error = await response.json();
        reject(new HTTPError(response?.status, response?.data?.detail));
        break;
    }
  })
}

export async function responseTextParse(response) {
  const result = await response.text();
  switch (response.status) {
    case 200:
      return result;
    case 400:
      throw result;
    case 401:
      throw result;
    default:
      throw result;
  }
}

class HTTPError extends Error{
  constructor(status, message) {
    super()
    this.status = status;
    this.message = message;
  }
}

function sessionExpired(){
  // check sessionToken and logout if expired
  sessionStorage.removeItem("ags_token");
  sessionStorage.removeItem("sso_token");
  sessionStorage.removeItem("forgerock_token");
  localStorage.setItem(
    "sessionInfo",
    JSON.stringify({ expired: true, isNotify: false })
  ); 
  window.location('/login');
  return 'you are not authorized';
}