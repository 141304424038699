import React from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Box, Stack, Tooltip, Button, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useEffect, useState } from "react";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import uploadIcon from "../assets/uploadIcon.svg";
// Import react-circular-progressbar module and styles
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { _MAX_FILE_SIZE_ } from "../Utils/constants";

import ChangingProgressProvider from "../Common/ChangingProgressProvider";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
function Dropzone(props) {
  const { files, setFiles, isSubmitClicked, region, setRegion } = props;

  const [errorState, setErrorState] = useState({
    error: false,
    key: "",
    errorMessage: "",
    errorCode: "error",
  });

  const removeFile = () => {
    setFiles(null);
    setErrorState((prev) => ({
      ...prev,
      error: false,
      errorMessage: "",
      key: "",
      errorCode: "",
    }));
  };
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      accept: {
        "application/vnd.ms-excel": [],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      },
      maxFiles: 1,
      multiple: false,
    });

  useEffect(() => {
    setErrorState((prev) => ({
      ...prev,
      error: false,
      errorMessage: "",
      key: "",
      errorCode: "",
    }));
    if (acceptedFiles.length > 0) {
      var fileSize = acceptedFiles[0].size;

      var fileSizeInKB = fileSize / 1024; // this would be in kilobytes defaults to bytes

      if (fileSizeInKB > _MAX_FILE_SIZE_) {
        setErrorState((prev) => ({
          ...prev,
          error: true,
          errorMessage: `Selected file size cannot be more than 25MB`,
          key: "file",
          errorCode: "error",
        }));
      } else {
        setFiles(acceptedFiles[0]);
      }
    }
    if (fileRejections.length > 0) {
      setErrorState((prev) => ({
        ...prev,
        error: true,
        errorMessage: `Selected file is not allowed. Please provide a file with valid extension.`, //${fileRejections[0]?.type}`,
        key: "file",
        errorCode: "error",
      }));
    }
  }, [acceptedFiles, fileRejections]);
  const thumbs = (
    <Box
      style={{
        width: "inherit",
        inlineSize: "150px",
        overflowWrap: "break-word",
        // display: "flex",
        // justifyContent: "center",
        // flexDirection: "column",
      }}
    >
      <Stack direction="row" key={files?.name} alignSelf={"center"}>
        <AttachFileOutlinedIcon fontSize="small"></AttachFileOutlinedIcon>
        <Tooltip title={files?.name}>
          <Typography noWrap textOverflow="ellipsis">
            {files?.name}
          </Typography>
        </Tooltip>

        <DeleteOutlineOutlinedIcon
          fontSize="small"
          onClick={(e) => {
            removeFile();
            e.preventDefault();
          }}
          sx={{ cursor: "pointer", ml: 3 }}
        ></DeleteOutlineOutlinedIcon>
      </Stack>
      {isSubmitClicked && (
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#4b286d" }} />

          {/* <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
            {(percentage) => (
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  pathTransitionDuration: 0.15,
                  textColor: "#4B286D",
                  pathColor: "#2B8000",
                  trailColor: "#66cc00",
                })}
              />
            )}
          </ChangingProgressProvider> */}
          <Typography my={1}>Uploading... </Typography>
        </Box>
      )}
    </Box>
  );
  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Grid item md={12} padding={2}>
        {!files ? (
          <Box
            {...getRootProps({ className: "dropzone" })}
            style={{
              width: "inherit",
              cursor: "pointer"
            }}
          >
            {!files && (
              <input
                className="input-zone"
                {...getInputProps()}
                multiple={false}
              />
            )}
            <Box
              sx={{
                // height: "18rem",
                // borderStyle: "dashed",
                // borderColor: "#4B286D",
                // borderRadius: 4,
                // borderWidth: "1px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                color: "#223e80",
              }}
            >
              {files && <>{thumbs}</>}

              {!files && (
                <Box
                  sx={{
                    color: "rgba(75, 40, 109, 1)",
                    fontWeight: 400,
                    display: "flex",
                    flexDirection: "column",
                    padding: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={uploadIcon}
                    alt="uploadIcon"
                    width="12%"
                    height="auto"
                  />
                  <Box
                    sx={{ display: "flex", flexDirection: "row", mt: "1rem" }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Drop Your Data File Here, Or
                      <Typography
                        style={{
                          display: "inline-block",
                          marginLeft: "0.25rem",
                          color: "#3983D0",
                          cursor: "pointer",
                        }}
                      >
                        Browse
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography
                    style={{
                      alignSelf: "center",
                      fontSize: "0.8rem",
                      marginBottom: "0.5rem",
                      color: "#818189",
                    }}
                  >
                    Data File (.xls)
                  </Typography>

                  {/* <Button
                  style={{
                    background: "rgba(75, 40, 109, 1)",
                    color: "white",
                    fontSize: "1.4rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  Browse Files
                </Button> */}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              // width: "18rem",
              // height: "15rem",
              // marginLeft: 2,
              justifyContent: "center",
              gap: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              color: "#223e80",
            }}
          >
            {files && <>{thumbs}</>}

            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }} size="small">
              <InputLabel id="demo-select-small-label">Region</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={region}
                label="Region"
                onChange={(e) => { setRegion(e.target.value) }}
              >
                <MenuItem value={"GLOBAL"}>Global</MenuItem>
                <MenuItem value={"APAC"}>APAC</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        {
          <Box height={"inherit"} sx={{ cursor: "default" }}>
            {/* {!errorState.error && (
              <Stack direction="row">
                <Typography
                  //my={1}
                  ml={2}
                  fontSize="0.775rem"
                  fontWeight="600"
                  color={"info"}
                >
                  {"Note: "}
                </Typography>
                <Box ml={1} sx={{ display: "flex", flexDirection: "column" }}>
                  <Stack direction="row" spacing={1.5}>
                    <Typography fontSize="0.775rem" color={"info"}>
                      {`1.`}
                    </Typography>
                    <Typography fontSize="0.775rem" color={"info"}>
                      {`File size cannot be more than 2MB`}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography fontSize="0.775rem" color={"info"}>
                      {`2.`}
                    </Typography>
                    <Typography fontSize="0.775rem" color={"info"}>
                      {`Allowed File Format are ${allowedFileFormats.map(
                        (x) => ` .${x}`
                      )}`}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            )} */}
            <Stack direction="column">
              {errorState.error && errorState.key === "file" && (
                <Typography
                  mx={2}
                  textAlign={"center"}
                  fontSize="0.775rem"
                  color={errorState?.errorCode}
                  sx={{ wordWrap: "break-all" }}
                >
                  {errorState.errorMessage}
                </Typography>
              )}
            </Stack>
          </Box>
        }
      </Grid>
    </Grid>
  );
}

export default Dropzone;
