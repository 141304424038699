import React from "react";
import { useState } from "react";
import { Button, Grid, Alert, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Appbar from "../appbar/Appbar";
import Sidebar from "../sidebar/Sidebar";
import Dropzone from "../Dropzone";
import styles from "./uploadpage.module.scss";

import services from "../../services/services";

export default function UploadPage(props) {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [files, setFiles] = useState(null);
  const [region, setRegion] = useState("GLOBAL");
  const navigate = useNavigate();

  const [responseError, setResponseError] = useState({
    status: "",
    message: "",
  });

  const handleUpload = async () => {
    setResponseError({status: ""});
    services.uploadFile(files, region).then(res=>{
      navigate("/downloads", { state: { id: res.id } });
    }).catch(err => {
      setSubmitClicked(false);
      setResponseError(err);
    });
  };
  return (
    <Grid
      container
      style={{
        height: "calc(100vh - 3.4rem)",
        background: "#F1F5F8",
        alignContent: "center",
        justifyContent: "center",
        marginTop: "-0.8rem",
        flexDirection: "column"
      }}
    >
      {responseError.status && (<Alert sx={{m: '1rem auto'}} severity="error">{responseError.message}</Alert>)}
      <Grid
        item
        md={4}
        width={"32rem"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"end"}
      >
        <Grid
          item
          md={12}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          padding={2}
          sx={{
            minHeight: "18rem",
            width: "100%",
            borderStyle: "dashed",
            borderColor: "#4B286D",
            borderRadius: 4,
            borderWidth: "1px",
            background: "white",
          }}
        >
          <Dropzone
            files={files}
            setFiles={setFiles}
            region={region}
            setRegion={setRegion}
            isSubmitClicked={submitClicked}
          />
          <Button
            className={styles.buttoncls}
            disabled={files ? false : true}
            onClick={() => {
              setSubmitClicked(!submitClicked);
              handleUpload();
            }}
            style={{ width: "auto" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      {/* <Grid item md={12} display={"flex"} justifyContent={"center"}></Grid>
      <Grid item md={12} display={"flex"} justifyContent={"center"}>
        <Button
          onClick={() => {
            navigate("/recent-files");
          }}
        >
          Processed Files
        </Button>
      </Grid> */}
    </Grid>
  );
}
