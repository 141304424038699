import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  List,
  Avatar,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListItemText from "@mui/material/ListItemText";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import BarChartIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import styles from "../appbar/appbar.module.scss";
import logo from "../../assets/logo.svg";
import LoadingSpinner from "../../Utils/Loader/LoadingSpinner";
import { useNavigate } from "react-router-dom";

import menu from "../../assets/menu_icon.png";
import {
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  Home,
  LogoutOutlined,
} from "@mui/icons-material";
import services from "../../services/services";
import userServices from "../../services/userServices";
const drawerWidth = 200; 

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    position: "relative",
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [sidebarMenus, setSidebarMenus] = React.useState([]);
  const [userImage, setUserImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [loading, setLoading] = useState(false);

  let userName = sessionStorage.getItem("user_info") || "";
  let userRole = sessionStorage.getItem("user_role") || "";

  const navigate = useNavigate();
  const { orgName, orgId, header } = props;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    setLoading(true);
    services.logout().then(res=>{
      sessionStorage.clear();
      navigate("/login");
      setLoading(false);
    });
  };

  const sidebarMenusAll = [
    {
      key: "home",
      menuName: "Home",
      menuIcon: HomeOutlinedIcon,
      isAdminMenu: false,
      link: "/",
    },
    {
      key: "admin-console",
      menuName: "Admin Console",
      menuIcon: AdminPanelSettingsOutlined,
      isAdminMenu: true,
      link: "/admin-dashboard",
    },
    {
      key: "uploads",
      menuName: "Recent Files",
      menuIcon: DescriptionOutlinedIcon,
      isAdminMenu: false,
      link: "/recent-files",
    },
    {
      key: "all-uploads",
      menuName: "All Files",
      menuIcon: DescriptionOutlinedIcon,
      isAdminMenu: true,
      link: "/all-recent-files",
    },
    {
      key: "master-table",
      menuName: "Master Configuration Table",
      menuIcon: TableChartOutlinedIcon,
      isAdminMenu: true,
      link: "/master-table",
    },
    {
      key: "looker",
      menuName: "Looker",
      menuIcon: BarChartIcon,
      isAdminMenu: false,
      link: "/looker-table",
    },
    {
      key: "faq",
      menuName: "FAQs",
      menuIcon: LiveHelpIcon,
      isAdminMenu: false,
      link: "/faq",
    },
    {
      key: "logout",
      menuName: "Logout",
      menuIcon: LogoutOutlined,
      isAdminMenu: false,
      clickFunction: logout,
    },
  ];
  const handleListItemClick = (event, index, to=null, clickFunction=null) => {
    setSelectedIndex(index);
    if(clickFunction) clickFunction();
    if(to) navigate(to);
  };

  // const handleClick = (event, index, clickFunction) => {
  //   setSelectedIndex(index);
  //   clickFunction();
  // };

  const filterSidebarMenus = (menuList) => {
    return sidebarMenusAll.filter((value) => {
      return menuList.includes(value.key)
    });
  };

  const getUserDetails = () => {
    if(sessionStorage.getItem("user_image") && sessionStorage.getItem("user_image") != 'undefined') {
      setUserImage(sessionStorage.getItem("user_image"));
    }
    services.getSidebarMenus().then((response) => {
      // create sidebarMenus from response object
      setSidebarMenus(filterSidebarMenus(response));
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    // set selected item depending on URL
    setSelectedIndex(sidebarMenus.findIndex(item => {
      return item.link === window.location.pathname
    }));
  }, [sidebarMenus]);

  return (
    <Grid container sx={{ display: "flex" }}>
      {loading && <LoadingSpinner></LoadingSpinner>}
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Box className={styles.appBardiv}>
          <img
            width="20%"
            height="auto"
            src={logo}
            alt="Telus Health"
            onClick={() => {
              navigate("/");
            }}
            className={styles.logoImage}
            sx={{ flexGrow: 1 }}
          />
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Box marginRight={"0.5rem"}>
              <Typography
                style={{
                  color: "#3E4245",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                {userName ? capitalizeWords(userName) : ""}
              </Typography>
              <Typography style={{ color: "#4D7CFE", fontSize: "0.8rem" }}>
                ({userRole ? userRole : ""})
              </Typography>
            </Box>
            <Avatar sx={{ height: "2rem", width: "2rem" }}>
              {userImage ? (
                <img
                  src={userImage}
                  alt="Profile"
                  style={{ height: "2rem", width: "2rem" }}
                />
              ) : (
                <AccountCircleIcon sx={{ fontSize: "2rem" }} />
              )}
            </Avatar>
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          minWidth: drawerWidth,
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
       
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon /> 
            )}
          </IconButton>
          <div style={{ fontFamily: "Helvetica" ,color: "#4B286D"}}>Menu</div>
         
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            minWidth: drawerWidth
          }}>
          {sidebarMenus.map((item, index) => {
            const Icon = item.menuIcon;
            return (
              <Tooltip title={item.menuName} placement="left">
                <ListItem
                  // component={Link}
                  // to={item.link}
                  className={styles.menuItem}
                  selected={index === selectedIndex}
                  onClick={(event) => 
                    handleListItemClick(event, index, item.link, item.clickFunction)
                  }
                  button
                  key={index}
                  style={
                    selectedIndex === index ? { backgroundColor: "#4B286D" } : {}
                  }
                >                
                  <Icon
                    style={
                      selectedIndex === index
                        ? { color: "white" }
                        : { color: "#4B286D" }
                    }
                  />
                  <ListItemText
                    primary={item.menuName}
                    primaryTypographyProps={{
                      variant: "subtitle2",
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                    style={
                      selectedIndex === index
                        ? { color: "white" }
                        : { color: "#4B286D" }
                    }
                  />
                </ListItem>
              </Tooltip>
            )
          })}
        </List>
      </Drawer>
    </Grid>
  );
}
