import QOneStep1 from "../../assets/images/Q1image1.png";
import QOneStep2 from "../../assets/images/Q1image2.png";
import QOneStep3 from "../../assets/images/Q1image3.png";
import QOnestep4 from "../../assets/images/Q1image4.png";
import QOneStep5 from "../../assets/images/Q1image5.png";
import QOneStep7 from "../../assets/images/Q1image7.png";

import QTwoStep1 from "../../assets/images/Q2image1.png";
import Q2Step2 from "../../assets/images/Q2image2.png";
import QTwoStep4 from "../../assets/images/Q2image4.png";
import Q3Step1 from "../../assets/images/Q3image1.png";
import Q3Step2 from "../../assets/images/Q3image2.png";
import Q3Step4 from "../../assets/images/Q3image4.png";
export const FAQs = [
  {
    id: "1",
    question: "How do I upload and process spreadsheets?      ",
    ans: {
      title: "",
      lines: [],
      steps: [
        "Click the 'Browse Files' button to select your spreadsheet from your device. You can also drag and drop the file for processing.",
        "Once you've chosen your file, click the 'Submit' button.",
        "You'll see a Circular Progress Bar showing the upload status.",
        "After the upload is complete, you'll be taken to an interim processing screen. Don't worry; our system is hard at work fixing your spreadsheet.",
        "When the processing is finished, you'll be directed to the download page.",
        "Here, you can download your corrected and cleaned spreadsheet for any further corrections required.",
        "If you have more files to process, simply click on the 'Telus Health' logo. This will take you back to the upload page, and you can start the next upload.",
      ],
      images: [
        QOneStep1,
        QOneStep2,
        QOneStep3,
        QOnestep4,
        QOneStep5,
        null,
        QOneStep7,
      ],
    },
  },
  {
    id: "2",
    question: "How do I access the past processed files?    ",
    ans: {
      title: "",
      lines: [],
      steps: [
        "If you wish to access files that were processed in the past, click on the hamburger menu icon provided in the portal.",
        "From the menu that appears, select 'Processed Files'.",
        "You'll be taken to a page that displays the most recent six files that were processed using the portal. You'll see the file names and the timestamps when they were processed.        ",
        "To download any of these past files, simply click on the Download icon provided next to the file name.",
        "If you want to go back to the upload page to process new files, just click on the 'Telus Health' logo. This will take you back to the upload page, and you can start a new upload.",
      ],
      images: [QTwoStep1, Q2Step2, null, QTwoStep4, null],
    },
  },
  {
    id: "3",
    question:
      "How do I access the past processed files of all vendors(Admins)?",
    ans: {
      title: "",
      lines: [],
      steps: [
        "If you wish to access files that were processed in the past, click on the hamburger menu icon provided in the portal.        ",
        "From the menu that appears, select 'All Recent Uploads'",
        "You'll be taken to a page that displays all the recent files that were processed by all the partners using the portal. You'll see the file names and the timestamps when they were processed. ",
        "To download any of these past files, simply click on the Download icon provided next to the file name.",
        "If you want to go back to the upload page to process new files, just click on the 'Telus Health' logo. This will take you back to the upload page, and you can start a new upload.",
      ],
      images: [Q3Step1, Q3Step2, null, Q3Step4, null],
    },
  },
];
