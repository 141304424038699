import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// declare module "@mui/material/styles" {
//   interface Theme {
//     backgrounds: {
//       level1: string;
//       level2: string;
//       level3?: string;
//     };
//     status: {
//       danger: string;
//     };
//     breakpoints: any;
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     backgrounds?: {
//       level1?: string;
//       level2?: string;
//       level3?: string;
//     };
//     status: {
//       danger: string;
//     };
//   }
// }

export const LightThemeConfig = {
  typography: {
    fontFamily: '"Helvetica", sans-serif',
    button: {
      textTransform: "none",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue",
          "&.Mui-disabled": {
            pointerEvents: "unset",
            cursor: "not-allowed",
            opacity: 0.5,
          },
          // "&:hover": {
          //   backgroundColor: "green",
          // },
        },
        // startIcon: {
        //   ".Mui-disabled &": {
        //     color: "red",
        //   },
        //   color: "white",
        // },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "1.4rem",
          padding: "2px",
     
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue",
          borderRadius: "1.4rem",
          padding:"0.125rem",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // backgroundColor: "red",
          borderRadius: "1.4rem",
          padding: "0px 0px 0px 0px",
        },
      },
    },
  },
};

export const LightTheme = responsiveFontSizes(createTheme(LightThemeConfig));
