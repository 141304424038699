import React, { useEffect, useState, useRef } from "react";
import styles from "./Login.module.scss";
import logo from "../../assets/logo.svg";
import {
  Grid,
  IconButton,
  FormControl,
  Box,
  Alert,
  FormLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import services from "../../services/services";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LoadingSpinner from "../../Utils/Loader/LoadingSpinner";
import { EMAIL_VALIDATION_REGEX } from "../../Utils/constants";

const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorState, setErrorState] = useState({
    error: false,
    errorMessage: "",
    key: "",
  });
  const [responseError, setResponseError] = useState({
    status: "",
    message: "",
  });
  const [loginState, setLoginState] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const app = useRef();
  const auth = useRef();
  const provider = useRef();

  function captureCredentials(e, type) {
    // console.log("captureCredentials", loginState);

    if (type === "username") {
      const emailId = e.target.value.trim();
      setLoginState((prevState) => ({
        ...prevState,
        username: emailId,
      }));
      if (!emailId.match(EMAIL_VALIDATION_REGEX)) {
        return setErrorState((prevState) => ({
          ...prevState,
          error: true,
          errorMessage: "Enter Valid Email Id.",
          key: "username",
        }));
      } else {
        return setErrorState((prevState) => ({
          ...prevState,
          error: false,
          errorMessage: "",
          key: "username",
        }));
      }
    } else {
      setLoginState((prevState) => ({
        ...prevState,
        password: e.target.value.trim(),
      }));
    }
  }

  function handleGoogleSignIn(response) {
    setLoading(true);
    verifyLogin(response);
  }

  // BE call to verify the user that just signed in
  const verifyLogin = async (res) => {
    services
    .loginReq(res)
    .then((response) => {
        // backend response once wrapped in JWT. BE logic needs rework
        if (response && response.access_token) {
          sessionStorage.setItem("ags_token", response.access_token);
          sessionStorage.setItem("refresh", response.refresh_token);
          sessionStorage.setItem("user_info",response.first_name + " " + response.last_name);
          sessionStorage.setItem("user_role", response.role);
          sessionStorage.setItem("user_image", response.image);
          // if (response.first_login === true) {
          //   navigate("/update-password");
          if (response.role.toUpperCase() === "ADMIN") {
            navigate("/admin-dashboard");
          } else {
            navigate("/");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setResponseError(err);
        setLoading(false);
      });
  };

  const PASSWORD_LENGTH = 30;

  // Internal user sign in 
  useEffect(() => {
    services.getFirebaseCredentials().then(res => {
      /*global google*/
      google.accounts.id.initialize({
        client_id: res.clientId,
        callback: handleGoogleSignIn
      });

      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outlined",
        size: "large",
        width: "260px",
        text: "Sign in with Telus Health"
      });

      var firebaseConfig = {
        apiKey: res.apiKey,
        authDomain: res.authDomain
      };
      app.current = initializeApp(firebaseConfig);
      auth.current = getAuth(app.current, {});
      provider.current = new OAuthProvider(res.providerId);
      provider.current.addScope("email");
      provider.current.addScope("openid");
      provider.current.addScope("fr:idm:*");
      provider.current.setCustomParameters({
        "scopes": "email openid fr:idm:*",
      });
    })
  }, []);

  // external user sign in 
  function handleExternalSignIn() {
    // let email = loginState.username; //"testuser@test.com";
    // let password = loginState.password; //"asdf123";

    signInWithPopup(auth.current, provider.current)
      .then((result) => {
        // Get the ForgeRock access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        sessionStorage.setItem("forgerock_token", credential.accessToken);
        sessionStorage.setItem("idToken", credential.idToken);
        verifyLogin(credential);
      })
      .catch((error) => {
        // Handle error.
        setResponseError({status: '500', message: 'Error while communicating with Auth provider'});
        alert(error);
        setLoading(false);
      });
  }

  return (
    <Grid container direction={'column'} className={styles.body}>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {responseError.status && <Alert sx={{mb: '1rem'}} severity="error">{responseError.message}</Alert>}
      <Grid item md={3} className={styles.loginContainer}>
        <Grid item md={12} className={styles.logoPlaceholder}>
          <img
            width="100%"
            height="100%"
            src={logo}
            alt="Telus Health"
            // sx={{ flexGrow: 1 }}
          />
        </Grid>
        <Grid item md={12}>
          {/* <Box>
            <FormControl fullWidth>
              <FormLabel required htmlFor="email" className={styles.loginInput}>
                Email
              </FormLabel>
              <TextField
                onChange={(e) => captureCredentials(e, "username")}
                id="email"
                disabled={loginState.loading}
                placeholder="Email Id"
                error={errorState.key === "username" && errorState.error}
                helperText={
                  errorState.key === "username" &&
                  errorState.error &&
                  errorState.errorMessage
                }
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "1.4rem",
                  },
                }}
                inputProps={{
                  style: {
                    padding: "0.5rem",
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box mt={1}>
            <FormControl fullWidth>
              <FormLabel
                required
                htmlFor="password"
                className={styles.loginInput}
              >
                Password
              </FormLabel>
              <TextField
                onChange={(e) => captureCredentials(e, "password")}
                disabled={loginState.loading}
                error={errorState.key === "password" && errorState.error}
                id="password"
                placeholder="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === 'Enter') handleSubmit()
                }}
                helperText={
                  errorState.key === "password" &&
                  errorState.error &&
                  errorState.errorMessage
                }
                FormHelperTextProps={{
                  sx: {
                    bgcolor: "background.paper",
                    m: 0,
                    px: 1,
                    pt: 0.5,
                  },
                }}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      //  sx={{ background: "transparent" }}
                    >
                      <IconButton
                        // sx={{ color: "rgb(232, 240, 254)" }}

                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon
                            // sx={{ color: "rgba(70, 90, 126, 0.4))" }}
                            fontSize="small"
                            sx={{ p: 0 }}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            fontSize="small"
                            sx={{ p: 0 }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "1.4rem",
                  },
                }}
                inputProps={{
                  maxLength: PASSWORD_LENGTH,
                  style: {
                    padding: "0.5rem",
                  },
                }}
              />
            </FormControl>
          </Box> */}
          <Box mt={2}>
            <button
              type="submit"
              className={styles.loginButton}
              onClick={() => {
                setLoading(true);
                handleExternalSignIn(loginState);
              }}
            >
              Sign in with Partner ID
            </button>
            {/* <div class> */}
            <div id="signInDiv"></div>
            {/* <div id="message">Loading...</div> */}
            {/* </div> */}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
