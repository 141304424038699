import React from "react";
import Table from "@mui/material/Table";
import moment from "moment";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"; // import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
  Grid,
  Alert,
  Snackbar,
} from "@mui/material";
import services from "../../services/services";
import styles from "./table.module.scss";

export default function BasicTable() {
  const [filesData, setFilesData] = useState(null);
  // const [loader, setLoader] = useState(true);
  const [responseError, setResponseError] = useState({
    status: "",
    message: "",
  });

  useEffect(() => {
    // console.log("useEffect");
    fetchFileData();
  }, []);

  const handleDownload = (row) => {
    services.downloadFileReq(row.name, row.name).catch((err) => {
      // err.message = "Error: Processing Incomplete or Invalid File";
      setResponseError(err);
    });
  };
  const fetchFileData = async () => {
    await services
      .getFiles()
      .then((response) => {
        setFilesData(response);
      })
      .catch((err) => {
        setFilesData([]);
        setResponseError(err);
      });
  };
  const clearError = () => {
    setResponseError(false);
  };

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      // alignItems={"center"}
    >
      {responseError.status && (
        <Snackbar
          sx={{ mt: "5.25rem" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(responseError)}
          onClose={clearError}
          autoHideDuration={5000}
        >
          <Alert severity="error">{responseError.message}</Alert>
        </Snackbar>
      )}

      <Grid item md={12} marginX={2}>
        <Typography color={"#3E4245"} alignSelf={"flex-start"} my={1}>
          Past Processed Files
        </Typography>
        <TableContainer component={Paper} style={{ alignSelf: "center" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={styles.headerclass}>
              <TableRow>
                <TableCell>
                  <Typography className={styles.headerText}>
                    File Name
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography className={styles.headerText}>User</Typography>
                </TableCell>

                <TableCell>
                  <Typography className={styles.headerText}>
                    Time of Processing
                  </Typography>
                </TableCell>

                <TableCell className={styles.headerText} align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filesData && !filesData.length && (
                <TableCell colSpan={4}>
                  <Typography textAlign={"center"} color={"#3E4245"}>
                    No Recent Files
                  </Typography>
                </TableCell>
              )}
              {filesData ? (
                filesData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      // className={styles.rowText}
                    >
                      <Typography className={styles.rowText}>
                        {row.name ? row.name : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      // className={styles.rowText}
                    >
                      <Typography className={styles.rowText}>
                        {row.user?.first_name
                          ? `${row.user?.first_name} ${row.user?.last_name}`
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={styles.rowText}>
                        {row.created_at
                          ? moment(row.created_at).format("DD-MM-YYYY hh:mm A")
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => handleDownload(row)}
                      >
                        <FileDownloadOutlinedIcon
                          color="secondary"
                          style={{ fontSize: "1.5rem" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      style={{ height: "396px", width: "100%" }}
                      sx={{ bgcolor: "grey.150" }}
                    ></Skeleton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
