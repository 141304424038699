import React from "react";
import { Dialog } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingSpinner() {
  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          background: "transparent",
          minWidth: "100%",
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <CircularProgress style={{ color: "#4b286d" }} />
    </Dialog>
  );
}
