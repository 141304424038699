import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Alert,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { Delete } from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import styles from "../edit-user/edituser.module.scss";

import LoadingSpinner from "../../../Utils/Loader/LoadingSpinner";
import userServices from "../../../services/userServices";

export default function DeleteUser(props) {
  const { openModal, userData, closeModal, deleteData } = props;

  // const usersState = state.users as UsersState;

  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  function handleClose() {
    // reset app state
    setApiResponse(null);
    setLoading(false);
    closeModal(false);
  }
  const deleteUsercall = () => {
    setLoading(true);
    userServices
      .deleteUser(userData)
      .then((response) => {
        if (response) {
          setLoading(false);
          deleteData(true);
          closeModal(false);
          setApiResponse({
            // ...prevState,
            code: "success",
            message: "User Deleted Successfully",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setApiResponse({
          // ...prevState,
          code: "error",
          message: "Failed to Update User : " + e,
        });
        console.log("catch if", e);
      });
  };
  return (
    <Dialog
      open={openModal}
      disableScrollLock
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: 420,
          pb: 1.5,
          zIndex: 8000,
        },
      }}
    >
      {loading && <LoadingSpinner></LoadingSpinner>}
      {apiResponse && <Alert severity={apiResponse["code"]}>{apiResponse["message"]}</Alert>}
      <DialogTitle
        sx={{
          boxShadow: 1,
          color: "secondary.main",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <QubeIcon style={{ fontSize: "2rem", marginRight: 8 }} /> */}
        <AccountCircleOutlinedIcon
          sx={{ fontSize: "2rem", marginRight: "1rem" }}
        />
        <Typography sx={{ textTransform: "capitalize" }}>
          {userData["first_name"]} {userData["last_name"]}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 2, py: 1, mt: 2 }}>
        <Typography textAlign="center">
          Are you sure you want to delete this user ?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          my: 1,
          mx: 5,
        }}
      >
        <Button
          className={styles.normalBtncls}
          onClick={handleClose}
          // autoFocus
        >
          Cancel
        </Button>
        <Button className={styles.buttoncls} onClick={deleteUsercall}>
          Delete User
        </Button>
      </DialogActions>
    </Dialog>
  );
}
