import React from "react";
import { useEffect, useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import Appbar from "../appbar/Appbar";
import Sidebar from "../sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import services from "../../services/services";
import userServices from "../../services/userServices";
import styles from "./PageNotFound.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import uploadPageImg from "../../assets/uploadpageimg.svg";

export default function PageNotFound(props) {
  const { state } = useLocation();
  const [loader, setLoader] = useState(true);
  const [fileProcessResp, setFileprocess] = useState(null);
  const [isAPItimeout, setAPITimeout] = useState(false);

  useEffect(() => {
    // console.log("useEffect");
    fetchData();
  }, []);

  const fetchData = async () => {
    if (state.id) {
      let encodedID = encodeURIComponent(state.id);
      services
        .checkFileProcess(encodedID)
        .then((response) => {
          if (response) {
            setLoader(false);
            if (response.statusCode && response.statusCode === 1) {
              setLoader(false);
              setAPITimeout(true);
            } else setFileprocess(response);
          }
        })
        .catch((e) => {
          // console.log("catch if", e);
        });
    }
  };
  const handleDownload = () => {
    services.downloadFileReq(fileProcessResp.id, fileProcessResp.filename);
  };

  return (
    <Grid container>
      {/* <Sidebar orgId={"Q1234567"} orgName={"Quantiphi"}></Sidebar> */}
    
        <Grid
          container
          md={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            flexDirection={"column"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            height={window.innerHeight - 100}
          >
            <div>
              <Typography style={{ wordWrap: "break-word" }}>
                Looks like you have landed on the Wrong Page
              </Typography>
            </div>
          </Grid>
        </Grid>
      
        
      
    </Grid>
  );
}
