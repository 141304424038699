import React from 'react'
import {Navigate, useLocation} from "react-router-dom"

const AuthorizedRoutes = ({children}) => {
    let location = useLocation();

    if (sessionStorage.getItem("user_role") !== 'ADMIN') {
        return <Navigate to="/page-not-found" state={{from: location}} replace />
    } 
 return children

};

export default AuthorizedRoutes;