import {
  fetchList,
  handleFileUpload,
  handleFileDownload,
  getFileProcess,
  LoginUrl,
  fetchTablesUrl,
  fetchTableDataUrl,
  createUserUrl,
  fetchAllUsersUrl,
  downloadAllUsersUrl,
  updateUserUrl,
  deleteUserUrl,
  updatePasswordUrl,
} from "./environment.dev";
import responseParse from "./responseParser";
import { interceptor } from "./interceptor";
import { isExpired, decodeToken } from "react-jwt";

const createUser = async (body) => {
  return interceptor(`${createUserUrl}`, {
    method: "POST",
    body: JSON.stringify(body),
    timeout: 50000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ForgeRock-Token": sessionStorage.getItem("forgerock_token") || ""
    },
  }).then((response)=> {
    return response.data
  }).catch((error)=> {
     return responseParse(error.response);
  });

};
const updateUser = async (body) => {
  return interceptor(`${updateUserUrl}`, {
    method: "POST",
    body: JSON.stringify(body),
    timeout: 50000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ForgeRock-Token": sessionStorage.getItem("forgerock_token") || ""
    },
  }).then((response)=> {
    return response.data
  }).catch((error)=> {
     return responseParse(error.response);
  });


};

const deleteUser = async (body) => {
   return interceptor(`${deleteUserUrl}`, {
    method: "POST",
    body: JSON.stringify(body),
    timeout: 50000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ForgeRock-Token": sessionStorage.getItem("forgerock_token") || ""
    },
  }).then((response)=> {
    return response.data
  }).catch((error)=> {
     return responseParse(error.response);
  });

};

const getAllUser = async () => {
  return  interceptor(`${fetchAllUsersUrl}`, {
    method: "GET",
    timeout: 50000,
  }).then((response)=> {
    return response.data
  }).catch((error)=> {
     return responseParse(error.response);
  });

};
const downloadAllUsers = async () => {
  const response = await interceptor(`${downloadAllUsersUrl}`, {
    method: "GET",
    timeout: 50000,
    responseType: 'blob'
  }).then((res) => {
    console.log(res)
    return res.data
  });

  const blobData = new Blob([response], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blobData);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "Users-list");
  document.body.appendChild(link);
  link.click();
};

 const getUserDetails = ()=> {

  const token = sessionStorage.getItem('ags_token')

  if(token) {
    const decodedToken = decodeToken(token);
    return decodedToken
  } else {
    return null
  }
  
}

export default {
  createUser,
  getAllUser,
  downloadAllUsers,
  updateUser,
  deleteUser,
  getUserDetails
};



