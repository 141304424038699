import React from "react";
import Table from "@mui/material/Table";
import moment from "moment";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"; // import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
  Grid,
  Alert,
  Snackbar
} from "@mui/material";
import services from "../../services/services";
import styles from "./allTable.module.scss";

export default function AllRecentFiles() {
  const [filesData, setFilesData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [responseError, setResponseError] = useState({
    status: "",
    message: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    // console.log("useEffect");
    fetchFileData(page,rowsPerPage);
  }, []);

  const handleDownload = (row) => {
    services.downloadFileReq(row.name, row.name).catch(err=>{
      setResponseError(err)
    });
  };
  const fetchFileData = async (page,max_downloads) => {
    setLoader(true);
    const response = await services
      .getAllFiles(page,max_downloads)
      .then((response) => {
        if (response && response.data) {
          setFilesData(response.data);
          // console.log("filesData", filesData);
          setTotalCount(response.total_count);
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
    // console.log("filesData", response);
  };

  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setPage(newPage);
    fetchFileData(newPage,rowsPerPage);
  };
  const clearError = () => {
    setResponseError(false);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchFileData(page,event.target.value);

  };
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      // alignItems={"center"}
    >
        {responseError.status && (
          <Snackbar
          sx={{mt: '5.25rem'}}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(responseError)}
          onClose={clearError}
          autoHideDuration={5000}
          ><Alert severity="error">{responseError.message}</Alert>
          </Snackbar>
        )}
      {/* <Sidebar header={"Processed Files"}></Sidebar> */}
      {/* <Box 
  ></Box> */}
      {/* <Grid item md={10}> */}

      <Grid item md={12} padding={2}>
        <Typography color={"#3E4245"} alignSelf={"flex-start"} my={1}>
          Past Processed Files
        </Typography>
        <TableContainer component={Paper} style={{ alignSelf: "center" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={styles.headerclass}>
              <TableRow>
                <TableCell>
                  <Typography className={styles.headerText}>
                    File Name
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography className={styles.headerText}>User</Typography>
                </TableCell>

                <TableCell>
                  <Typography className={styles.headerText}>Vendor</Typography>
                </TableCell>

                <TableCell>
                  <Typography className={styles.headerText}>
                    Time of Processing
                  </Typography>
                </TableCell>

                <TableCell className={styles.headerText} align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filesData && !(filesData.length) && (<TableCell colSpan={5}>
                <Typography textAlign={"center"} color={"#3E4245"}>
                  No Recent Files
                </Typography>
              </TableCell>)}
              {!loader ? (
                filesData &&
                filesData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      // className={styles.rowText}
                    >
                      <Typography className={styles.rowText}>
                        {row.name ? row.name : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      // className={styles.rowText}
                    >
                      <Typography className={styles.rowText}>
                        {row.user?.first_name
                          ? `${row.user?.first_name} ${row.user?.last_name}`
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      // className={styles.rowText}
                    >
                      <Typography className={styles.rowText}>
                        {row.user?.vendor
                          ? `${row.user?.vendor.name} ${row.user?.vendor.country}`
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography className={styles.rowText}>
                        {row.created_at
                          ? moment(row.created_at).format("DD-MM-YYYY hh:mm A")
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(e) => handleDownload(row)}
                      >
                        <FileDownloadOutlinedIcon
                          color="secondary"
                          style={{ fontSize: "1.5rem" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      style={{ height: "396px", width: "100%" }}
                      sx={{ bgcolor: "grey.150" }}
                    ></Skeleton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            // sx={{
            //   "& .MuiTablePagination-toolbar": {
            //     minHeight: 0,
            //     px: 2,
            //     py: 1,
            //     bgcolor: "#E9ECF2",
            //     color: "secondary.main",
            //   },
            // }}
            // rowsPerPage={10}
            count={totalCount}
            page={page}
            labelDisplayedRows={({
              from,
              to,
              count,
            }) => `Showing Results of ${from}–${to} of ${
              totalCount !== -1 ? totalCount : `more than ${to}`
            }
                    `}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
          {/* <TablePagination
            sx={{
              "& .MuiTablePagination-toolbar": {
                minHeight: 0,
                px: 2,
                py: 1,
                bgcolor: "#E9ECF2",
                color: "secondary.main",
              },
            }}
            rowsPerPageOptions={[5, 10, 15, 20]}
            colSpan={8}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelDisplayedRows={({
              from,
              to,
              count,
            }) => `Showing Results of ${from}–${to} of ${
              count !== -1 ? count : `more than ${to}`
            }
                `}
            // onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={QTablePaginationActions}
            component="div"
          /> */}
        </TableContainer>
      </Grid>
    </Grid>
  );
}
