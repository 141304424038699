import axios from "axios";
import { fetchNewToken} from "./environment.dev";
const instance = axios.create();

instance.interceptors.response.use(
  response => {
    return response
  },
  async (error) => {
      const prevRequest = error?.config;
      if ((error?.response?.status === 403 || error?.response?.status === 400) && !prevRequest?.sent) {
          prevRequest.sent = true;

          return interceptor(`${fetchNewToken}`, {
            method: "GET",
            timeout: 50000,
          }).then((response)=> {
            sessionStorage.setItem("ags_token", response.data.access_token);
            sessionStorage.setItem("refresh", response.data.refresh_token);
            sessionStorage.setItem(
              "user_info",
              response.data.first_name + " " + response.data.last_name
            );
            sessionStorage.setItem("user_role", response.data.role);
  
            prevRequest.headers['Authorization'] = sessionStorage.getItem("ags_token")
            return instance(prevRequest)
          }).catch((error)=> {
              window.open('login', "_self")
          });

      }
      return Promise.reject(error);
  }
);

export const interceptor = async (url, options) => {
  let sessionToken = sessionStorage.getItem("ags_token") || "";
  let refresh = sessionStorage.getItem("refresh") || "";
  const controller = new AbortController();
  const timeout = options?.timeout || 12000;
  const id = setTimeout(() => controller.abort(), timeout);
  const wrapperOptions = options
    ? {
        ...options,
        headers: {
          ...options?.headers, Authorization: `${sessionToken}`, refresh: refresh
        },
        signal: controller.signal,
      }
    : {
        headers: {
          Authorization: `${sessionToken}`, refresh: refresh
        },
      };

  const response = await instance({data: options?.body,url,method: options.method, responseType: options.responseType ? 'blob':'' , headers: wrapperOptions.headers });
  
  clearTimeout(id);

  return response;
};

