import React, { useEffect, useState } from "react";
import styles from "./updatePassword.module.scss";
import logo from "../../../assets/logo.svg";
import {
    Grid,
    IconButton,
    FormControl,
    Box,
    Alert,
    FormLabel,
    TextField,
    InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    getAuth,
    updatePassword,
} from "firebase/auth";
import services from "../../../services/services";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Google } from "@mui/icons-material";
import { EMAIL_VALIDATION_REGEX } from "../../../Utils/constants";


const UpdatePassword = () => {

    const [passwordDetails, setPasswordDetails] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    })
    const [showPassword, setShowPassword] = useState(false);
    const [errorState, setErrorState] = useState({
        error: false,
        errorMessage: "",
        key: "",
    });
    const [responseErrorMsg, setresponseErrorMsg] = useState({
        code: "",
        message: "",
    });
    const [loginState, setLoginState] = useState({
        username: "",
        password: "",
    });

    const navigate = useNavigate();

    function captureCredentials(e, type) {
        // console.log("captureCredentials", loginState);

        if (type === "username") {
            const emailId = e.target.value.trim();
            setLoginState((prevState) => ({
                ...prevState,
                username: emailId,
            }));
            if (!emailId.match(EMAIL_VALIDATION_REGEX)) {
                return setErrorState((prevState) => ({
                    ...prevState,
                    error: true,
                    errorMessage: "Enter Valid Email Id.",
                    key: "username",
                }));
            } else {
                return setErrorState((prevState) => ({
                    ...prevState,
                    error: false,
                    errorMessage: "",
                    key: "username",
                }));
            }
        } else {
            setLoginState((prevState) => ({
                ...prevState,
                password: e.target.value.trim(),
            }));
        }
    }
    const handleSubmit = () => {
        services.updatePassword(passwordDetails).then(()=> {
            
             if (sessionStorage.getItem("user_role") === "ADMIN") {
                navigate("/admin-dashboard");
              } else {
                navigate("/");
              }
        })
    };

    const updatePasswordDetails = (key, value) => {
        let newPasswordDetails = Object.assign(passwordDetails)
        newPasswordDetails[key] = value
        setPasswordDetails(newPasswordDetails)
    }

    const PASSWORD_LENGTH = 30;
    var firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app, {});

    return (
        <Grid container className={styles.body}>
            <Grid item md={3} className={styles.loginContainer}>
                <Grid item md={12} className={styles.logoPlaceholder}>
                    Reset Password
                </Grid>

                <Grid item md={12}>

                    <Box mt={1}>
                        <FormControl fullWidth>
                            <FormLabel
                                required
                                htmlFor="New Password"
                                className={styles.loginInput}>
                                Password
                            </FormLabel>
                            <TextField
                                onChange={(e) => updatePasswordDetails("new_password", e.target.value,)}
                                disabled={loginState.loading}
                                id="new_password"
                                placeholder="New Password"
                                variant="outlined"
                                type={"password"}
                                fullWidth
                                FormHelperTextProps={{
                                    sx: {
                                        bgcolor: "background.paper",
                                        m: 0,
                                        px: 1,
                                        pt: 0.5,
                                    },
                                }}
                                InputProps={{

                                    endAdornment: (
                                        <InputAdornment
                                            position="end">

                                        </InputAdornment>
                                    ),
                                    style: {
                                        borderRadius: "1.4rem",
                                        marginBottom: "5px"
                                    },
                                }}
                                inputProps={{
                                    maxLength: PASSWORD_LENGTH,
                                    style: {
                                        padding: "0.5rem",
                                    },
                                }}
                            />
                            <FormLabel
                                required
                                htmlFor="Confirm Password"
                                className={styles.loginInput}
                            >
                                Confirm Password
                            </FormLabel>

                            <TextField
                                onChange={(e) => updatePasswordDetails("confirm_password", e.target.value,)}
                                disabled={loginState.loading}
                                id="confirm_password"
                                placeholder="Confirm Password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                FormHelperTextProps={{
                                    sx: {
                                        bgcolor: "background.paper",
                                        m: 0,
                                        px: 1,
                                        pt: 0.5,
                                    },
                                }}
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment
                                            position="end">

                                        </InputAdornment>
                                    ),
                                    style: {
                                        borderRadius: "1.4rem",
                                    },
                                }}
                                inputProps={{
                                    maxLength: PASSWORD_LENGTH,
                                    style: {
                                        padding: "0.5rem",
                                    },
                                }}
                            />

                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <button
                            type="submit"
                            className={styles.loginButton}
                            onClick={() => handleSubmit()}
                        >
                            Submit
                        </button>
                        {/* <div class> */}
                        <div id="signInDiv"></div>
                        {/* <div id="message">Loading...</div> */}
                        {/* </div> */}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default UpdatePassword

