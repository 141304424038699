import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, count) {
  return { name, count};
}

export default function ErrorCountsTable(props) {
    const { counts } = props;

    const rows = [
        createData('Corrected Cells', counts.corrected_cells),
        createData('Cells Needing Manual Correction', counts.manual_correction_required),
        createData('Unchanged Cells', counts.unchanged_cells)
      ];

    return (
        <TableContainer sx={{ margin: "1rem 0" , minWidth: 320, maxWidth: 650 }} component={Paper}>
        <Table aria-label="simple table">
            {/* <TableHead>
            <TableRow>
                <TableCell sx={{fontWeight: 600}}>Type of cell</TableCell>
                <TableCell sx={{fontWeight: 600}} align="right">Count</TableCell>
            </TableRow>
            </TableHead> */}
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row" sx={{fontWeight: 600}}>
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.count}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}
