export default {
  FETCH_BARCODE_FILE_LIST: "FETCH_BARCODE_FILE_LIST",
  DELETE_BARCODE_FILES: "DELETE_BARCODE_FILES",
  RESET_BARCODE_FILES: "RESET_BARCODE_FILES",
  CURRENT_BARCODE_FILE: "CURRENT_BARCODE_FILE",
};
export const dateFormat = "DD-MM-YYYY HH:mm:ss";
export const defaultPageLimit = 10;
export const AUTO_REFRESH_INTERVAL = 30000;
export const CONFIGURE_MENU = "Configure";
export const numberPattern = new RegExp(/^(\+|00)[1-9][0-9 \-().]{7,32}$/);
export const STRING_WITH_SPACE = `^[a-zA-Z0-9]+[a-zA-Z0-9._-]*( [a-zA-Z0-9]+[a-zA-Z0-9._-]*)*$`;
export const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g);
export const normalStringRegex = /^[a-z\s]{1,255}$/i;
export const RoleList = [
  { value: "ADMIN", viewValue: "ADMIN" },
  { value: "PARTNER", viewValue: "PARTNER" },
];
export const EMAIL_VALIDATION_REGEX = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const PASSWORD_LENGTH = 12;
export const _MAX_FILE_SIZE_ = 1024 * 25;

export const passwordStrengthRegEx =
  /^(?=.{7,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$*!%^&+=]).*$/;
