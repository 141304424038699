import React, { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  Grid,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  Stack,
  Popover,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import UserTable from "./UsersTable/UserTable";
import styles from "./dashboard.module.scss";
import AddIcon from "@mui/icons-material/Add";
import CreateUser from "../create-user/CreateUser";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import services from "../../services/services";
import DatePicker from "../../Utils/datePicker/DatePicker";
import userServices from "../../services/userServices";
import Popper from '@mui/material/Popper';

export default function Dashboard(props) {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [files, setFiles] = useState(null);
  const navigate = useNavigate();
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [dateValues, setDateValues] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [resetFilter, setResetFilter] = useState(false);
  const open = Boolean(anchorEl);
  const [refreshUsersData, setRefreshUsersData] = useState(false);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);

    //
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedUser, setSelectedUser] = useState(null);
  console.log("selectedUser", selectedUser);

  const downloadData = () => {
    userServices.downloadAllUsers();
  };
  const resetFilters = () => {
    setDateValues([]);
    setResetFilter(true);
  };
  const FilterHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between">
        <Link
          // tabIndex={j}
          color="primary"
          underline="hover"
          component={"button"}
          onClick={resetFilters}
          // disabled={agent.disabled}
          sx={{
            textAlign: "left",
            cursor: "pointer",
            "&:focus": {
              textDecoration: "underline !important",
              outline: "none !important",
            },
            textDecoration: "underline !important",
            fontSize: "1.5rem",
            px: 1,
            my: 1,
            display: "flex",
          }}
        >
          <Typography
            fontSize={"1rem"}
            fontWeight={500}
            noWrap
            textOverflow="ellipsis"
            color={"#223E80"}
          >
            Reset All
          </Typography>
        </Link>
        <Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon fontSize="small" color="inherit" />
          </IconButton>
        </Box>
      </Box>
    );
  };
  return (
    <Grid
      container
      style={{
        textAlign: "center",
        justifyContent: "center",
      }}
      mt={2}
    >
      <Grid container md={12} padding={2}>
        <Grid
          item
          md={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={1}
        >
          <Typography
            style={{
              fontSize: "1rem",
              color: "#4b286d",
              textTransform: "uppercase",
              fontWeight: 550,
            }}
            // color={"secondary"}
            // fontWeight={500}
          >
            User List
          </Typography>
          <Grid display={"flex"} flexDirection={"row"}>
            <Button
              className={styles.buttoncls}
              onClick={() => setOpenCreateUser(true)}
              startIcon={<AddIcon color="inherit" fontSize="0.6rem" />}
            >
              Create User
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Button
              aria-describedby={open ? 'simple-popper' : undefined}
              className={styles.normalBtncls}
              onClick={handleFilterClick}
              startIcon={
                <FilterAltOutlinedIcon color="inherit" fontSize="0.6rem" />
              }
            >
              Filter By Date Created
            </Button>
            <Popper id={open ? 'simple-popper' : undefined} open={open} anchorEl={anchorEl}>
              <Box sx={{ borderRadius:1, p: 1, ml:2, bgcolor: '#ffffff', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px', }}>
                <FilterHeader></FilterHeader>

                <MenuItem>
                  <DatePicker
                    handleChange={(value) => setDateValues(value)}
                  ></DatePicker>
                </MenuItem>
              </Box>
            </Popper>
            {/* <Menu
              // disableScrollLock={true}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <FilterHeader></FilterHeader>

              <MenuItem>
                <DatePicker
                  handleChange={(value) => setDateValues(value)}
                ></DatePicker>
              </MenuItem>
            </Menu> */}
            {/* {open && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                color="white"
                PaperProps={{
                  sx: {
                    width: "30%",
                    boxShadow: "1px 1px 1px 1px #f2f2f2",
                    background: "white",
                  },
                }}
                sx={{ zIndex: 1000, overflow: "hidden" }}
              >
                <FilterHeader />
               
              </Popover>
            )} */}
            {dateValues && dateValues.length > 0 && (
              <>
                <Typography className={styles.rowText}>
                  Showing results between {dateValues[0]} and {dateValues[1]}
                </Typography>
                {/* <IconButton aria-label="close" onClick={handleClose}> */}
                <CloseIcon
                  className={styles.rowText}
                  fontSize="small"
                  color="inherit"
                  onClick={resetFilters}
                />
                {/* </IconButton> */}
              </>
            )}
          </Box>
          {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <DatePicker
                handleChange={(value) => setDateValues(value)}
              ></DatePicker>
            </MenuItem>
          </Menu> */}
          <Button
            className={styles.normalBtncls}
            onClick={() => downloadData()}
            startIcon={
              <FileDownloadOutlinedIcon color="inherit" fontSize="0.6rem" />
            }
          >
            Download
          </Button>
        </Grid>
      </Grid>
      <CreateUser
        openModal={openCreateUser}
        closeModal={() => setOpenCreateUser(false)}
        setRefreshUsersData={setRefreshUsersData}
      ></CreateUser>
      {/* {selectedUser && (
        <EditUser
          openModal={openEditUser}
          closeModal={() => setOpenEditUser(false)}
          userData={selectedUser}
        />
      )} */}

      <UserTable
        selectedUser={(user) => setSelectedUser(user)}
        openEditUser={(value) => setOpenEditUser(value)}
        dateValues={dateValues}
        resetFilter={resetFilter}
        refreshTable={refreshUsersData}
      ></UserTable>
    </Grid>
  );
}
