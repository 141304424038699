export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;

// api
export const fetchList = `${serverUrl}/api/v1/recent-files`;
export const fetchAllList = `${serverUrl}/api/v1/all-recent-files`;
export const handleFileUpload = `${serverUrl}/api/v1/upload`;
export const getFileProcess = `${serverUrl}/api/v1/is-file-processed`;
export const handleFileDownload = `${serverUrl}/api/v1/download`;
export const LoginUrl = `${serverUrl}/api/v1/auth/verify`; //`${serverUrl}/api/v1/auth/verify`; //`${serverUrl}/api/v1/auth/login`;
export const createUserUrl = `${serverUrl}/api/v1/users/create-user`;
export const fetchTablesUrl = `${serverUrl}/api/v1/master-table/table-names`;
export const fetchTableDataUrl = `${serverUrl}/api/v1/master-table/master-table-data`;
export const fetchAllUsersUrl = `${serverUrl}/api/v1/users/all`;
export const logoutUrl = `${serverUrl}/api/v1/users/logout`;
export const fetchAllVendorsUrl = `${serverUrl}/api/v1/vendor/all`;
export const fetchNewToken = `${serverUrl}/api/v1/auth/get-new-token`;
export const downloadAllUsersUrl = `${serverUrl}/api/v1/users/download-user-csv`;
export const updateUserUrl = `${serverUrl}/api/v1/users/update-user`;
export const deleteUserUrl = `${serverUrl}/api/v1/users/delete-user`;
export const updatePasswordUrl = `${serverUrl}/api/v1/users/update-password`;
export const getLookerEmbedUrl = `${serverUrl}/api/v1/looker/get-embed-url`;
export const getSidebarMenusUrl = `${serverUrl}/api/v1/get-sidebar-menus`;
export const getFirebaseCredentialsUrl = `${serverUrl}/api/v1/auth/get-firebase-credentials`;
