import * as React from "react";
import { Grid, Box } from "@mui/material";
import services from "../../services/services";


export default function LookerTable() {
  const [lookerEmbedUrl, setLookerEmbedUrl] = React.useState('');

  React.useEffect(() => {
    services.getLookerEmbed().then((response) => {
      setLookerEmbedUrl(response)
    });
  }, []);

  return (
    <Grid container mt={3}>
      <Grid
        item
        md={12}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box mx={2}>
          <iframe sandbox="allow-scripts allow-same-origin" style={{width: "100%", height: "calc(100vh - 7rem)"}} title="Looker Embed" src={lookerEmbedUrl}
          ></iframe>
        </Box>
      </Grid>
    </Grid>
  );
}
