import React from "react";
import { memo, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  IconButton,
  Button,
  AppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/logo.svg";
import MuiAppBar from "@mui/material/AppBar";
import styles from "./appbar.module.scss";
import { styled, useTheme } from "@mui/material/styles";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useNavigate } from "react-router-dom";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CreateUser from "../create-user/CreateUser";

export default memo(function Header(props) {
  const { orgName, orgId, header, openPopup } = props;
  const navigate = useNavigate();
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const handleClickOpen = () => {
    // console.log("handleclick");
    // openPopup(true);
    setOpenCreateUser(true);
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(open && {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   transition: theme.transitions.create(["margin", "width"], {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   marginRight: drawerWidth,
    // }),
  }));
  return (
    <Grid
      container
      md={12}
      sx={{
        boxShadow: " 0px 4px 4px #00000040",
        marginBottom: "1rem",
        background: "#f2f4f6",
      }}
    >
      <Grid
        item
        md={6}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Box>
          <Typography style={{ fontSize: "0.8rem", fontWeight: 600 }}>
            John Doe
          </Typography>
          <Typography style={{ color: "#4D7CFE", fontSize: "0.8rem" }}>
            (Admin)
          </Typography>
        </Box>
        <Avatar sx={{ height: "2.2rem", width: "2.2rem" }}>
          <img
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=50"
            alt="Profile"
          />
        </Avatar>
        <IconButton>
          <NotificationsOutlinedIcon color="primary"></NotificationsOutlinedIcon>
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon color="primary"></SettingsOutlinedIcon>
        </IconButton>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCreateUser(true)}
          sx={{
            justifyContent: "flex-start",
            height: "4.5ch",
            borderRadius: "0.5rem",
          }}
          disableElevation
          startIcon={<ControlPointOutlinedIcon color="inherit" />}
          size="small"
        >
          <Typography color="inherit" fontSize="0.8rem">
            Create User
          </Typography>
        </Button>
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          // background: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <img
          width="20%"
          height="auto"
          src={logo}
          alt="Telus Health"
          onClick={() => {
            navigate("/HomePage");
          }}
          className={styles.logoImage}
        />
        <MenuIcon />
      </Grid>
      <CreateUser
        openModal={openCreateUser}
        closeModal={() => setOpenCreateUser(false)}
      ></CreateUser>
    </Grid>
  );
});
