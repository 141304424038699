import {
  fetchList,
  handleFileUpload,
  handleFileDownload,
  getFileProcess,
  LoginUrl,
  fetchTablesUrl,
  fetchTableDataUrl,
  createUserUrl,
  fetchAllUsersUrl,
  downloadAllUsersUrl,
  updateUserUrl,
  deleteUserUrl,
  updatePasswordUrl,
  fetchAllVendorsUrl,
  fetchAllList,
  logoutUrl,
  getLookerEmbedUrl,
  getSidebarMenusUrl,
  getFirebaseCredentialsUrl
} from "./environment.dev";
import responseParse from "./responseParser";
import { interceptor } from "./interceptor";

// const getFilesAction=
export const getFilesAction = "fetchList";
export const uploadFileAction = "uploadFile";
export const downloadFileAction = "downloadFile";
export const getFileUploadResponseAction = "getFileUploadResponse";

const getFiles = async () => {
  return interceptor(`${fetchList}?max_downloads=6`, {
    method: "GET",
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const getAllFiles = async (pageNo = 0, max_downloads) => {
  return interceptor(
    `${fetchAllList}?max_downloads=${max_downloads}&page=${pageNo}`,
    {
      method: "GET",
      timeout: 50000,
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const checkFileProcess = async (id) => {
  return interceptor(`${getFileProcess}?file_id=${id}`, {
    method: "POST",
    // body: ,
    timeout: 300000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};
const uploadFile = async (file, region) => {
  let formdata = new FormData();

  if (file) formdata.append("file", file);
  return interceptor(`${handleFileUpload}?region=${region}`, {
    method: "POST",
    body: formdata,
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const downloadFileReq = async (id, filename) => {
  return interceptor(`${handleFileDownload}?file_id=${id}`, {
    method: "POST",
    timeout: 50000,
    responseType: "blob"
  })
    .then((response) => {
      const blobData = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      error.response.data.detail = "Error: Processing Incomplete or Invalid File"
      return responseParse(error.response);
    });
};

const loginReq = (body) => {
  return interceptor(`${LoginUrl}`, {
    method: "POST",
    body: body,
    timeout: 50000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};
const getTableName = async () => {
  return await interceptor(`${fetchTablesUrl}`, {
    method: "GET",
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const getTableData = async (tableKey, pageNo, limit) => {
  return interceptor(
    `${fetchTableDataUrl}?table_name=${tableKey}&page=${pageNo}&limit=${limit}`,
    {
      method: "GET",
      timeout: 50000,
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const getLookerEmbed = async () => {
  return interceptor(`${getLookerEmbedUrl}`, {
    method: "GET",
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const getSidebarMenus = async () => {
  return interceptor(`${getSidebarMenusUrl}`, {
    method: "GET",
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

// const createUser = async (body) => {
//   return interceptor(`${createUserUrl}`, {
//     method: "POST",
//     body: JSON.stringify(body),
//     timeout: 50000,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       "ForgeRock Token": sessionStorage.getItem("forgerock_token") || ""
//     },
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       return responseParse(error.response);
//     });
// };
// const updateUser = async (body) => {
//   return await interceptor(`${updateUserUrl}`, {
//     method: "POST",
//     body: JSON.stringify(body),
//     timeout: 50000,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       return responseParse(error.response);
//     });
// };

// const deleteUser = async (body) => {
//   return interceptor(`${deleteUserUrl}`, {
//     method: "POST",
//     body: JSON.stringify(body),
//     timeout: 50000,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       return responseParse(error.response);
//     });
// };

const updatePassword = async (body) => {
  return interceptor(`${updatePasswordUrl}`, {
    method: "POST",
    body: JSON.stringify(body),
    timeout: 50000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};
// const getAllUser = async () => {
//   return await interceptor(`${fetchAllUsersUrl}`, {
//     method: "GET",
//     timeout: 50000,
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       return responseParse(error.response);
//     });
// };

const getAllVendors = async () => {
  return interceptor(`${fetchAllVendorsUrl}`, {
    method: "GET",
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

const logout = async () => {
  return interceptor(`${logoutUrl}`, {
    method: "POST",
    timeout: 50000,
    headers: {
      "ForgeRock-Token": sessionStorage.getItem("forgerock_token") || "",
      "ForgeRock-IdToken": sessionStorage.getItem("idToken") || ""
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};


const getFirebaseCredentials = async () => {
  return interceptor(`${getFirebaseCredentialsUrl}`, {
    method: "GET",
    timeout: 50000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return responseParse(error.response);
    });
};

// const downloadAllUsers = async () => {
//   const response = await interceptor(`${downloadAllUsersUrl}`, {
//     method: "GET",
//     timeout: 50000,
//   }).then((res) => {
//     return res.blob();
//   });
//   const url = window.URL.createObjectURL(response);
//   const link = document.createElement("a");
//   link.href = url;
//   link.setAttribute("download", "Userslist");
//   document.body.appendChild(link);
//   link.click();
// };
export default {
  getFiles,
  uploadFile,
  downloadFileReq,
  checkFileProcess,
  loginReq,
  getTableName,
  getTableData,
  // createUser,
  // getAllUser,
  // downloadAllUsers,
  // updateUser,
  // deleteUser,
  updatePassword,
  getAllVendors,
  getAllFiles,
  logout,
  getLookerEmbed,
  getSidebarMenus,
  getFirebaseCredentials
};
